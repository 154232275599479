//import React from 'react';
//import ReactDOM from 'react-dom/client';
import Elskolden from './Elskolden';
//import './styles.scss';

// yarn start <-- to start the application

/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Elskolden />
    </React.StrictMode>
);*/

export default { "Elskolden": Elskolden }