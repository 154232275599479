import React from 'react';
import styled from 'styled-components';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//const Primary = '#002347'
//const Secondary = '#FFDA00'

const IntroContainer = styled.div`
    margin-top: 78px;
    min-height: 80vh;
    background-image: url('/static/img/northern-light.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
`

const Animate = styled.div`
    position: relative;
    transition: all .6s ease-out;
    ${({ animation }) => (animation === 'swipeLeft' ? 'right: 100vw;' : '')}
    ${({ animation }) => (animation === 'swipeRight' ? 'left: 100vw;' : '')}
    ${({ animation }) => (animation === 'opacity' ? 'opacity: 0;' : '')}

    &.activated {
        ${({ animation }) => (animation === 'swipeLeft' ? 'right: 0;' : '')}
        ${({ animation }) => (animation === 'swipeRight' ? 'left: 0;' : '')}
        ${({ animation }) => (animation === 'opacity' ? 'opacity: 1;' : '')}
    }
`

Animate.defaultProps = {
    animation: 'opacity'
}

const services = [
    {icon: 'img/icons/thunder-light.svg', alt: 'Lighting icon', text: 'Centralbyte, där vi byter det gamla proppskåpet till en ny fräsch normcentral med dvärgbrytare'},
    {icon: 'img/icons/light-bulb.svg', alt: 'Blub icon', text: 'Belysning, när badrummet är mörkt och det börjar bli svårt att raka sig, byter vi ut de gamla flimriga lysrören till ljus fin LED belysning'},
    {icon: 'img/icons/car-alt-1.svg', alt: 'Car icon', text: 'Laddboxar, för dig som vill ladda din bil hemma om du har elbil eller laddhybrid'},
    {icon: 'img/icons/energy-solar.svg', alt: 'Solar panel icon', text: 'Solceller, producera egen el på taket? Jo varför inte! Bra för både plånbok och miljö'}
]

class Elskolden extends React.Component {
    constructor() {
        super();

        this.state = {
            active: 'home',
            sectionOne: false,
            sectionTwo: false,
            sectionThree: false,
            form: {}
        }

        this.handleScroll = this.handleScroll.bind(this);

        this.introContainer = React.createRef();
        this.sectionOne = React.createRef();
        this.sectionTwo = React.createRef();
        this.sectionThree = React.createRef();
    }

    isElemVisible(elem) {
        const elementRect = elem.getBoundingClientRect();
        const { top, left, bottom, right } = elementRect;

        return top >= 0 && left >= 0 && bottom <= window.innerHeight && right <= window.innerWidth;
    }

    handleScroll() {
        if(this.isElemVisible(this.introContainer.current)) {
            this.setState({ active: 'home'})
        } else if(this.isElemVisible(this.sectionOne.current)) {
            this.setState({ active: 'about'})
        } else if(this.isElemVisible(this.sectionThree.current)) {
            this.setState({ active: 'contact'})
        }

        if (!this.state.sectionOne && this.isElemVisible(this.sectionOne.current)) {
            this.setState({ sectionOne: true })
        }
        if (!this.state.sectionTwo && this.isElemVisible(this.sectionTwo.current)) {
            this.setState({ sectionTwo: true })
        }
        if (!this.state.sectionThree && this.isElemVisible(this.sectionThree.current)) {
            this.setState({ sectionThree: true })
        }

        this.setState({ scroll: window.scrollY })
    }

    sendEmail(e) {
        e.preventDefault()

        let headers = {
            'Content-Type': 'application/json',
            'X-CSRFToken': document.getElementById("csrf_token").value
        }
        let url = '/send_message/'
        let data = this.state.form

        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: headers
        }).then(resp => console.log(resp))
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    render() {
        return <>
            <div className='bg-main-blue white'>
                <Navbar expand='lg' fixed="top" className='bg-main-blue'>
                    <Container className="p-0">
                        <Navbar.Brand className='white fw-bold me-0' href='#home'><img className="px-3" height="60px" src="/static/img/elskolden/logo-yellow.svg" alt='Logo' /></Navbar.Brand>

                        <Button variant="main-yellow" className='button d-md-none me-3' href='tel:0046739779010'>RING OSS</Button>

                        <Navbar.Collapse className='justify-content-center' id='basic-navbar-nav'>
                            <Nav>
                                <div className={`${this.state.active === 'home' ? 'white hover-main-yellow text-decoration-underline fw-bold' : 'white hover-main-yellow'} mx-3`} role='button' onClick={() => this.introContainer.current.scrollIntoView()}>Hem</div>
                                <div className={`${this.state.active === 'about' ? 'white hover-main-yellow text-decoration-underline fw-bold' : 'white hover-main-yellow'} mx-3`} role='button' onClick={() => this.sectionOne.current.scrollIntoView()}>Om oss</div>
                                <div className={`${this.state.active === 'contact' ? 'white hover-main-yellow text-decoration-underline fw-bold' : 'white hover-main-yellow'} mx-3`} role='button' onClick={() => this.sectionThree.current.scrollIntoView()}>Kontakt</div>
                            </Nav>
                        </Navbar.Collapse>

                        <Button variant="main-yellow" className='button d-none d-md-block' href='tel:0046739779010'>RING OSS</Button>
                    </Container>
                </Navbar>
            </div>

            <IntroContainer ref={this.introContainer} className='d-flex align-items-center white'>
                <div className='container'>
                    <div className='row justify-content-center text-center'>
                        <div className='col-12 col-md-6'>
                            <h1>När du kräver det bästa </h1>
                            <p>För dig som vill ha en trygg kontakt när det kommer till det elektriska</p>

                            <Button as='button' variant='main-yellow' className='button mx-2 mb-3 mb-md-0' onClick={() => this.sectionThree.current.scrollIntoView()}>KONTAKTA OSS</Button>
                            <Button as='button' variant='supp-grey' className='button border border-2 border-main-blue mx-2' onClick={() => this.sectionTwo.current.scrollIntoView()}>VÅRA TJÄNSTER</Button>
                        </div>

                    </div>
                </div>
            </IntroContainer>

            <section ref={this.sectionOne} className='bg-supp-grey'>
                <div className='container space'>
                    <div className='row justify-content-between align-items-center'>
                        <Animate animation='swipeLeft' className={`col-12 col-md-6 ${this.state.sectionOne ? 'activated' : ''}`}>
                            <h2 className='main-blue'>Viktigast för oss är nöjda kunder</h2>
                            <p style={{maxWidth: '450px'}}>När du kräver bra service, god kommunikation och första klassigt arbete till rätt pris, står vi redo som auktoriserade och pålitliga elektriker. Men viktigast för oss är självklart du, din familj och din anläggnings säkerhet.</p>
                        </Animate>

                        <Animate animation='swipeRight' className={`col-12 col-md-6 text-center ${this.state.sectionOne ? 'activated' : ''}`}>
                            <img className="img-fluid main-blue-filter" alt='Power connection' src='/static/img/icons/power-icon.svg' />
                        </Animate>
                    </div>
                </div>
            </section>

            <section ref={this.sectionTwo} className='bg-main-blue white'>
                <div className='container'>
                    <div className='row justify-content-between align-items-center'>
                        <div className={`col-12 col-md-6 p-0 ${this.state.sectionTwo ? 'activated' : ''}`}>
                            <img className='img-fluid shadow' src='/static/img/electrician-working.jpg' alt='Electrician installing a solar panel' />
                        </div>

                        <Animate animation='opacity' className={`col-12 col-md-6 col-lg-5 py-5 py-md-0 ${this.state.sectionTwo ? 'activated' : ''}`}>
                            <h2 className='main-yellow'>Personlig och proffsig service</h2>
                            <p className='mb-4'>Vi fokuserar på Norra Stockholm (Vallentuna, Täby, Österåker) men tar även emot uppdrag utanför vårat fokusområde. Alla tänkbara elarbeten, från det enstaka uttaget till helt nya dragningar i hela huset.</p>
                        </Animate>
                    </div>
                </div>
            </section>

            <section className='bg-supp-cloud dark-blue'>
                <div className='container space'>
                    <div className='row justify-content-between align-items-stretch'>
                        {services.map(s => {
                            return <div className='col-12 col-md-6 col-lg-3 mb-4'>
                                <div className='d-flex flex-md-column align-items-center rounded-5 bg-supp-grey h-100 mx-3 p-4'>
                                    <div className="bg-supp-blue rounded-pill p-3 mb-2">
                                        <img src={`/static/${s.icon}`} className='main-yellow-filter' height='40px' alt={s.alt} />
                                    </div>
                                    
                                    <p className="text-center my-auto mx-3">{s.text}</p>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>

            <section ref={this.sectionThree} className='bg-supp-grey'>
                <div className='container space'>
                    <div className='row justify-content-center'>
                        <Animate animation='opacity' className={`col-12 col-md-6 ${this.state.sectionThree ? 'activated' : ''}`}>
                            <h2>Kontakta oss</h2>
                            <p style={{maxWidth: '400px'}}>Vi är bara ett samtal bort, du kan ringa, smsa eller mejla välj det som passar dig bäst.</p>

                            <p>
                                Tel: <a href='tel:0046739779010' className="main-blue text-decoration-none">073 977 90 10</a><br />
                                E-post: <a href='mailto:info@elskolden.se' className="main-blue text-decoration-none">info@elskolden.se</a>
                            </p>
                        </Animate>

                        <div className={`col-12 col-md-6 px-md-5 ${this.state.sectionThree ? 'activated' : ''}`}>
                            {/*<Form onSubmit={(e) => this.sendEmail(e)}>
                                <div className='row'>
                                    <div className='col-12 col-md-6'>
                                    <Form.Group className="mb-3 pe-3" controlId="formName">
                                    <Form.Label>Namn</Form.Label>
                                    <Form.Control type="text" placeholder="Skriv ditt namn" onChange={(e) => this.setState({ form: {...this.state.form, name: e.target.value}})}/>
                                </Form.Group>
                                    </div>

                                    <div className='col-12 col-md-6'>
                                    <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>E-post</Form.Label>
                                    <Form.Control type="email" placeholder="Skriv ditt e-post" onChange={(e) => this.setState({ form: {...this.state.form, email: e.target.value}})}/>
                                </Form.Group>
                                    </div>
                                </div>
                                

                                

                                <Form.Group controlId="formMessage">
                                    <Form.Label>Meddelande</Form.Label>
                                    <Form.Control as="textarea" rows={5} placeholder="Skriv ditt meddelande..." onChange={(e) => this.setState({ form: {...this.state.form, message: e.target.value}})}/>
                                </Form.Group>

                                <Button variant='main-blue' type='submit' className='button mt-3'>SKICKA</Button>
                    </Form>*/}
                        </div>
                    </div>
                </div>
            </section>

            <footer className='bg-main-blue white py-4'>
                <div className='container'>
                    <div className='row justify-content-between align-items-end'>
                        <div className='col-12 col-md-4 align-self-start'>
                            <img src='/static/img/elskolden/logo-yellow.svg' className="mb-4" height="50px" alt="Logo" />
                        </div>

                        <div className='col-12 col-md-4'>
                            <p className='mb-0'>Address:<br />
                            <b>Kårstaby 8A</b><br />
                            <b>186 96 Vallentuna</b></p>
                            <p className='mb-0'>Org nr: <b>971104-7457</b></p>
                        </div>

                        <div className='col-12 col-md-4'>
                            <div className="mb-3">
                                <a href='https://www.facebook.com/share/UD7aeQH1213KFYRW/' target='_blank' className='me-2'><img src='/static/img/social/facebook.svg' height='25px' width='25px' className='white-filter hover-main-yellow-filter' alst='Facebook logo' /></a>
                                <a href='https://www.instagram.com/elskolden' target='_blank'><img src='/static/img/social/instagram.svg' height='25px' width='25px' className='white-filter hover-main-yellow-filter' alt='Instagram logo' /></a>
                            </div>

                            <p className='mb-0'>Copyright © 2024 Elskölden</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    }
}

export default Elskolden;